import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import SettingsSidebar from "../layouts/SettingsSidebar";
import { img10 } from "../_components/Imagepath";
import { useTranslation } from 'react-i18next';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';

import {
    CustomDropDownField,
    CustomInputField
} from "../CustomComponents/ReusableComponents";

const AccountSettings = () => {
    const { t } = useTranslation();
    const { auth: authToken, branchId } = useAuth();
    const [companyInfo, setCompanyInfo] = useState(null);
    const [isEditing, setIsEditing] = useState(false);


    // const companyId = sessionStorage.getItem('branch_id');
    const companyId =  1    //branchId;

    const [formData, setFormData] = useState({
        name: '',
        organization_name: '',
        industry_type_id: '',
        company_type_id: '',
        currency_id: '',
        fiscal_year_start: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        zip_code: '',
        website: '',
        report_basis: '',
        us_company_id: '',
        tax_type: '',
        tax_id_num: '',
    });

    const [industryTypes, setIndustryTypes] = useState([]);
    const [companyTypes, setCompanyTypes] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCompanyInfo = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_V1_BASE_URL}/companies/${companyId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setCompanyInfo(data);
                    setFormData({
                        name: data.name || '',
                        organization_name: data.organization_name || '',
                        industry_type_id: data.industry_type_id || '',
                        company_type_id: data.company_type_id || '',
                        currency_id: data.currency_id || '',
                        fiscal_year_start: data.fiscal_year_start || '',
                        address_line_1: data.address_line_1 || '',
                        address_line_2: data.address_line_2 || '',
                        city: data.city || '',
                        state: data.state || '',
                        zip_code: data.zip_code || '',
                        website: data.website || '',
                        report_basis: data.report_basis || 'Accrual',
                        us_company_id: data.us_company_id || '',
                        tax_type: data.tax_type || '',
                        tax_id_num: data.tax_id_num || '',
                    });
                } else {
                    console.error('Failed to fetch company info');
                    setError('Failed to fetch company info');
                }
            } catch (error) {
                console.error('An unexpected error occurred:', error);
                setError('An unexpected error occurred');
            }
        };

        const fetchDropdownData = async () => {
            setLoading(true);
            setError(null);

            try {
                const requestData = [
                    { model_name: 'IndustryType' },
                    { model_name: 'CompanyType' },
                    { model_name: 'Currency' },
                ];

                const apiReqHeaders = {
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                        'Content-Type': 'application/json',
                    }
                };

                const response = await axios.post(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
                    { models: requestData },
                apiReqHeaders
                );

                const dropdownData = response.data.content;
                
                setIndustryTypes(dropdownData.IndustryType || []);
                setCompanyTypes(dropdownData.CompanyType || []);
                setCurrencies(dropdownData.Currency || []);
            } catch (error) {
                console.error('An unexpected error occurred:', error);
                setError('Failed to load dropdown data');
            } finally {
                setLoading(false);
            }
        };

        fetchCompanyInfo();
        fetchDropdownData();
    }, [companyId, authToken, branchId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSave = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_V1_BASE_URL}/companies/${companyId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                const updatedCompany = await response.json();
                setCompanyInfo(updatedCompany);
                setIsEditing(false);
            } else {
                console.error('Failed to update company info');
                setError('Failed to update company info');
            }
        } catch (error) {
            console.error('An unexpected error occurred:', error);
            setError('An unexpected error occurred');
        }
    };

    const reportBasisOptions = [
        { value: 'Accrual', label: t('Accrual') },
        { value: 'Cash', label: t('Cash') },
    ];

    const apiEndpoint = `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`;
    console.log('API Endpoint:', apiEndpoint); // Check if the endpoint is correct


    return (
        <>
            <Header />
            <Sidebar />
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="row">
                        <div className="col-xl-3 col-md-4">
                            <SettingsSidebar />
                        </div>
                        <div className="col-xl-9 col-md-8">
                            <div className="card">
                                <div className="card-body w-100">
                                    <div className="content-page-header">
                                        <h5 className="setting-menu">
                                            {t('Account Settings')}
                                        </h5>
                                    </div>
                                    {loading ? (
                                        <div>Loading...</div>
                                    ) : error ? (
                                        <div className="error">{error}</div>
                                    ) : companyInfo ? (
                                        <div className="row">
                                            <div className="profile-picture col-lg-12">
                                                <div className="upload-profile me-2">
                                                    <div className="profile-img">
                                                        <img
                                                            id="blah"
                                                            className="avatar"
                                                            src={img10}
                                                            alt="profile-img"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="img-upload">
                                                    <label className="btn btn-primary">
                                                        Upload new picture{" "}
                                                        <input type="file" />
                                                    </label>
                                                    <Link className="btn btn-remove">
                                                        Delete
                                                    </Link>
                                                    <p className="mt-1">
                                                        {t('Logo Should be minimum 152 * 152 Supported File format JPG,PNG,SVG')}
                                                    </p>
                                                </div>
                                            </div>
                                                <div className="form-title col-lg-12">
                                                    <h5>{t('General Information')}</h5>
                                                </div>
                                                <CustomInputField
                                                    inputSize="col-lg-6"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    name="name"
                                                    labelText={t('Company Name')}
                                                    inputType="text"
                                                    placeholder={t('Enter company Name')}
                                                    
                                                />
                                                <CustomInputField
                                                    inputSize="col-lg-6"
                                                    value={formData.organization_name}
                                                    onChange={handleChange}
                                                    name="organization_name"
                                                    labelText={t('Organization Name')}
                                                    inputType="text"
                                                    placeholder={t('Acme Corporation')}
                                                    
                                                />
                                                <CustomDropDownField
                                                    options={industryTypes.map(type => ({ value: type.id, label: type.name }))}
                                                    name="industry_type_id"
                                                    labelText={t('Industry Type')}
                                                    value={formData.industry_type_id}
                                                    onChange={handleChange}
                                                    
                                                />
                                                <CustomDropDownField
                                                    options={companyTypes.map(type => ({ value: type.id, label: type.name }))}
                                                    name="company_type_id"
                                                    labelText={t('Company Type')}
                                                    value={formData.company_type_id}
                                                    onChange={handleChange}
                                                    
                                                />
                                                <CustomDropDownField
                                                    options={currencies.map(currency => ({ value: currency.id, label: currency.name }))}
                                                    name="currency_id"
                                                    labelText={t('Currency')}
                                                    value={formData.currency_id}
                                                    onChange={handleChange}
                                                    
                                                />
                                                <CustomInputField
                                                    inputSize="col-lg-6"
                                                    value={formData.fiscal_year_start}
                                                    onChange={handleChange}
                                                    name="fiscal_year_start"
                                                    labelText={t('Fiscal Year Start')}
                                                    inputType="date"
                                                    
                                                />
                                                <CustomInputField
                                                    inputSize="col-lg-6"
                                                    value={formData.website}
                                                    onChange={handleChange}
                                                    name="website"
                                                    labelText={t('Website')}
                                                    inputType="text"
                                                    placeholder={t('Website URL')}
                                                    
                                                />
                                                <CustomDropDownField
                                                    options={reportBasisOptions}
                                                    name="report_basis"
                                                    labelText={t('Report Basis')}
                                                    value={formData.report_basis}
                                                    onChange={handleChange}
                                                    
                                                />
                                                <CustomInputField
                                                    inputSize="col-lg-6"
                                                    value={formData.us_company_id}
                                                    onChange={handleChange}
                                                    name="us_company_id"
                                                    labelText={t('US Company ID')}
                                                    inputType="text"
                                                    
                                                />
                                                <CustomInputField
                                                    inputSize="col-lg-6"
                                                    value={formData.tax_type}
                                                    onChange={handleChange}
                                                    name="tax_type"
                                                    labelText={t('Tax Type')}
                                                    inputType="text"
                                                    
                                                />
                                                <CustomInputField
                                                    inputSize="col-lg-6"
                                                    value={formData.tax_id_num}
                                                    onChange={handleChange}
                                                    name="tax_id_num"
                                                    labelText={t('Tax ID Number')}
                                                    inputType="number"
                                                    
                                                />

<div className="col-lg-12">
                                            <div className="form-title pt-3">
                                                <h5>Address Information</h5>
                                            </div>
                                        </div>

                                        <>
                                            <CustomInputField
                                                inputSize="col-lg-6"
                                                labelText="Adress Line 1"
                                                inputType="text"
                                                className=""
                                                placeholder="Enter Adress Line 1 here"
                                            />
                                            <CustomInputField
                                                inputSize="col-lg-6"
                                                labelText="Adress Line 2"
                                                inputType="text"
                                                className=""
                                                placeholder="Enter Adress Line 2"
                                            />

                                            <CustomInputField
                                                inputSize="col-lg-6"
                                                labelText="City"
                                                inputType="text"
                                                className="Enter City Here"
                                            />

                                            <CustomInputField
                                                inputSize="col-lg-6"
                                                labelText="State"
                                                inputType=" text"
                                                className=""
                                            />
                                            <CustomInputField
                                                inputSize="col-lg-6"
                                                labelText="ZipCode"
                                                inputType="number"
                                                className=""
                                            />
                                        </>
{/* 
                                        <>
                                            <div className="form-title pt-3">
                                                <h5>Other Information</h5>
                                            </div>
                                            <CustomDropDownField
                                                options={options}
                                                name="accountType"
                                                id=""
                                                inputSize="col-lg-6"
                                                labelText="Industy type"
                                            />
                                            <CustomDropDownField
                                                options={options}
                                                name="accountType"
                                                id="Company-Type"
                                                inputSize="col-lg-6"
                                                labelText="Company Type"
                                            />
                                            <CustomDropDownField
                                                options={options}
                                                name="accountType"
                                                id=""
                                                inputSize="col-lg-6"
                                                labelText="Currency"
                                            />

                                            <CustomInputField
                                                inputSize="col-lg-6"
                                                labelText="Fiscal Year Start"
                                                inputType="Date"
                                                className=""
                                                placeholder="Corporation"
                                            />

                                            <CustomInputField
                                                inputSize="col-lg-6"
                                                labelText="Website"
                                                inputType="text"
                                                className=""
                                            />
                                            <CustomDropDownField
                                                options={options}
                                                name="Report Basis"
                                                id="Report_Basis"
                                                inputSize="col-lg-6"
                                                labelText="Report Basis"
                                            />
                                            <CustomInputField
                                                inputSize="col-lg-6"
                                                labelText="US Company ID"
                                                inputType=""
                                                className=""
                                            />
                                            <CustomInputField
                                                inputSize="col-lg-6"
                                                labelText="Tax Type"
                                                inputType="text"
                                                className=""
                                            />
                                            <CustomInputField
                                                inputSize="col-lg-6"
                                                labelText="Tax ID Number"
                                                inputType="number"
                                                className=""
                                            />
                                        </> */}


                                            
                                            <div className="btn-path text-end">
                                                {isEditing ? (
                                                    <>
                                                        <button
                                                            type="button"
                                                            className="btn btn-cancel bg-primary-light me-3"
                                                            onClick={() => setIsEditing(false)}
                                                        >
                                                            {t('Cancel')}
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={handleSave}
                                                        >
                                                            {t('Save Changes')}
                                                        </button>
                                                    </>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={() => setIsEditing(true)}
                                                    >
                                                        {t('Edit')}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div>{t('No company information available')}</div>
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccountSettings;
