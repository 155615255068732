import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import Loader from '../CustomComponents/Loader';

import AiaLogoWithName from '../assets/img/aia-logo-with-name.png';

function Register() {
    const [step, setStep] = useState(0);

    // const [domainUrl, setDomainUrl] = useState('');
    // const [selectedSubscription, setSelectedSubscription] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [emailOtp, setEmailOtp] = useState('');
    const [phoneOtp, setPhoneOtp] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(1);
    const [selectedCompanyType, setSelectedCompanyType] = useState(1);
    const [validationError, setValidationError] = useState('');
    const [registerAs, setRegisterAs] = useState('Company'); // New state for "Register As"


    const [countries, setCountries] = useState([]);
    const [companyTypes, setCompanyTypes] = useState([]);
    const [loading, setLoading] = useState(false); // Step 1: Add loading state

    const { authToken } = useAuth();

    const fetchDropdownData = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/get_dropdown_data`,
                {
                    models: [
                        { model_name: 'CompanyType' },
                        { model_name: 'Country' },
                    ],
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authToken}`
                    },
                }
            );

            const dropdownData = response.data.content;
            setCompanyTypes(dropdownData.CompanyType || []);
            setCountries(dropdownData.Country || []);
        } catch (err) {
            //Console error
        } finally {
            //finally if any action like loader stop
        }
    };

    useEffect(() => {
        fetchDropdownData();
    }, [authToken, step]);

    const handleNextStep = (e) => {
        e.preventDefault();

        // if (companyName.length) {
        //     setValidationError('Business Name Required.');
        //     return;
        // }

        setValidationError('');
        setStep(step + 1);
    };

    const handlePreviousStep = (e) => {
        e.preventDefault();
        setStep(step - 1);
    };

    const renderHeaderContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return "Registered As";
            case 1:
                return "Business Information";
            case 2:
                return "Business Contact";
            case 3:
                return "Verification";

        }
    }
    const handleRegister = async (e) => {
        e.preventDefault();
        setLoading(true); // Step 2: Set loading to true when registration starts

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/register`,
                {
                    name: `${firstName} ${lastName}`,
                    first_name: firstName,
                    last_name: lastName,
                    email,
                    phone,
                    email_otp: emailOtp,
                    phone_otp: phoneOtp,
                    company_name: companyName,
                    company_type_id: selectedCompanyType,
                    organization_name: companyName,
                    country_id: selectedCountry,
                    industry_type_id: 1,
                    currency_id: 1,
                    country_code: 'usd',
                    register_as: registerAs // Include the "Register As" value

                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.status === 200) {
                window.location.href = `${response.data.content.verify_registration_route}`;
            } else {
                console.error('Unexpected response:', response);
            }
        } catch (error) {
            setLoading(false); // Step 3: Set loading to false after the registration is complete
            if (error.response && error.response.status === 422) {
                const { content, message } = error.response.data;
                let errorMessages = message;

                // Safely check for field-specific errors
                if (content) {
                    for (const field in content) {
                        if (Object.hasOwn(content, field)) {
                            errorMessages += `\n${field}: ${content[field].join(', ')}`;
                        }
                    }
                }

                setValidationError(errorMessages);
            } else {
                console.error('Registration Failed:', error);
                setValidationError('An unexpected error occurred. Please try again.');
            }
        } finally {
            setLoading(false); // Step 3: Set loading to false after the registration is complete
        }
    };

    return (
        <div className="main-wrapper login-body">
            <div className="login-wrapper">
                <div className="container">
                    <img className="img-fluid logo-dark mb-2" src={AiaLogoWithName} alt="Logo" />

                    <div className="loginbox">
                        {/* <div id="global-loader"></div> */}
                        <div className="login-right">
                            <div className="login-right-wrap">
                                <h1>Register</h1>
                                <p className="account-subtitle">{renderHeaderContent(step)}</p>
                                <Loader isLoading={loading} className='loginbox' /> {/* Use Loader component here */}
                                {step === 0 && (
                                    < form onSubmit={handleNextStep} >
                                        <div className="input-block mb-3">
                                            <label className="form-control-label"></label>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="registerAs"
                                                    value="Company"
                                                    checked={registerAs === 'Company'}
                                                    onChange={(e) => setRegisterAs(e.target.value)}
                                                />
                                                <label className="form-check-label">Company</label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="registerAs"
                                                    value="CPA"
                                                    checked={registerAs === 'CPA'}
                                                    onChange={(e) => setRegisterAs(e.target.value)}
                                                />
                                                <label className="form-check-label">CPA</label>
                                            </div>
                                        </div>
                                        <div className="input-block mb-0">
                                            <button className="btn btn-lg btn-block btn-primary w-100" type="submit">
                                                Next
                                            </button>
                                        </div>
                                    </form >
                                )}
                                {step === 1 && (
                                    <form onSubmit={handleNextStep}>
                                        <div className="input-block mb-3">
                                            <label className="form-control-label">Business Name<span className="text-danger"> *</span></label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={companyName}
                                                onChange={(e) => setCompanyName(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="input-block mb-3">
                                            <label className="form-control-label">Business Type<span className="text-danger"> *</span></label>
                                            <select
                                                className="form-control"
                                                value={selectedCompanyType}
                                                onChange={(e) => setSelectedCompanyType(e.target.value)}
                                                required
                                            >
                                                <option value="">Select a Business Type</option>
                                                {companyTypes.map((type) => (
                                                    <option key={type.id} value={type.id}>
                                                        {type.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="input-block mb-3">
                                            <label className="form-control-label">Country<span className="text-danger"> *</span></label>
                                            <select
                                                className="form-control"
                                                value={selectedCountry}
                                                onChange={(e) => setSelectedCountry(e.target.value)}
                                                required
                                            >
                                                <option value="">Select a Country</option>
                                                {countries.map((country) => (
                                                    <option key={country.id} value={country.id}>
                                                        {country.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {validationError && (
                                            <div className="text-danger mb-3">
                                                {validationError}
                                            </div>
                                        )}
                                        <div className="input-block mb-0">
                                            <button className="btn btn-lg btn-block btn-primary w-100" type="submit">
                                                Next
                                            </button>
                                        </div>
                                        <div className="mt-3">
                                            <button className="btn btn-lg btn-block btn-secondary w-100" onClick={handlePreviousStep}>
                                                Previous
                                            </button>
                                        </div>
                                    </form>
                                )}

                                {step === 2 && (
                                    <form onSubmit={handleRegister}>
                                        <div className="input-block mb-3">
                                            <label className="form-control-label">First Name<span className="text-danger"> *</span></label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="input-block mb-3">
                                            <label className="form-control-label">Last Name<span className="text-danger"> *</span></label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="input-block mb-3">
                                            <label className="form-control-label">Email Address<span className="text-danger"> *</span></label>
                                            <input
                                                className="form-control"
                                                type="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="input-block mb-3">
                                            <label className="form-control-label">Phone<span className="text-danger"> *</span></label>
                                            <input
                                                className="form-control"
                                                type="tel"
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                            />
                                        </div>
                                        {validationError && (
                                            <div className="text-danger mb-3">
                                                {validationError}
                                            </div>
                                        )}
                                        <div className="input-block mb-0">
                                            <button className="btn btn-lg btn-block btn-primary w-100" type="submit">
                                                Register
                                            </button>
                                        </div>
                                        <div className="mt-3">
                                            <button className="btn btn-lg btn-block btn-secondary w-100" onClick={handlePreviousStep}>
                                                Previous
                                            </button>
                                        </div>

                                    </form>
                                )}

                                {step === 3 && (
                                    <form onSubmit={handleRegister}>
                                        <div className="input-block mb-3">
                                            <label className="form-control-label">Email OTP<span className="text-danger"> *</span></label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={emailOtp}
                                                onChange={(e) => setEmailOtp(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="input-block mb-3">
                                            <label className="form-control-label">Phone OTP<span className="text-danger"> *</span></label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={phoneOtp}
                                                onChange={(e) => setPhoneOtp(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="input-block mb-0">
                                            <button className="btn btn-lg btn-block btn-primary w-100" type="submit">
                                                Verify
                                            </button>
                                        </div>
                                        <div className="mt-3">
                                            <button className="btn btn-lg btn-block btn-secondary w-100" onClick={handlePreviousStep}>
                                                Previous
                                            </button>
                                        </div>
                                    </form>
                                )}

                                <div className="mt-4 text-center">
                                    <p>
                                        {/* Already have an account? <Link to="/login">Login</Link> */}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;