import React from "react";
import PropTypes from "prop-types";
// import { propTypes } from "react-bootstrap/esm/Image";
// import { Link } from "feather-icons-react/build/IconComponents";
import TextArea from "antd/es/input/TextArea";

const CustomInputField = ({ labelText, inputType, placeholder, className, inputWrapper, value, onChange, name }) => {
    return (
        <div className={`col-lg-4 col-md-6 col-sm-12 ${inputWrapper}`}>
            <div className="input-block mb-3">
                <label>{labelText}</label>
                <input
                    type={inputType || "text"}
                    className={`form-control ${className}`}
                    placeholder={placeholder || "Enter text here"}
                    value={value}  
                    name={name}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};

export const CustomTextArea = ({ className, rows, col, placeholder, labelText, value, onChange, name }) => {
    return (
        <>
            <label className="input-block mb-3">{labelText}</label>
            <TextArea
                col={col}
                className={`form-control ${className}`}
                placeholder={placeholder}
                rows={rows}
                value={value}
                name={name}
                onChange={onChange}
            />
        </>
    );
};


const CustomDropDownField = ({ options, name, id, labelText, dropDownSize, value, onChange }) => {
    return (
        <div className={`col-lg-4 col-md-6 col-sm-12 ${dropDownSize}`}>
            <div className="input-block mb-3">
                <label>{labelText}</label>
                <select
                    name={name}
                    className="form-control w-100"
                    style={{ border: "1px solid #ced4da" }} 
                    id={id}
                    value={value} 
                    onChange={onChange} 
                >
                    <option value="">Select</option>
                    {options.map((option, index) => (
                        <option key={index} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

const NewFooter = () => {
    return(
    <div className="preview-boxs py-3">
        {/* <Link to="/signature-preview-invoice">Preview Invoice</Link> */}
        <form action="/invoices" className="add-customer-btns text-end">
            <button type="reset" className="btn btn-primary cancel me-2">
                Cancel
            </button>
            <button type="reset" className="btn btn-primary cancel me-2">
                Save
            </button>
            <button type="submit" className="btn btn-primary">
                Save & Send
            </button>
        </form>
    </div>
    )
};

const ReusableButton =(classNames , ButtonTitle , functionOnClick) =>{
    <button className={classNames} onClick={functionOnClick}>
        {ButtonTitle}
    </button>
}
// Define prop types
CustomInputField.propTypes = {
    labelText: PropTypes.string,
    inputType: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    inputWrapper: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),  // Add value prop
    name: PropTypes.string,  // Add name prop
    onChange: PropTypes.func.isRequired,  // Add onChange prop
};

CustomDropDownField.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),  // Allow string or number
            label: PropTypes.string
        })
    ).isRequired,  // Ensure options are provided
    name: PropTypes.string,  // Add name prop
    id: PropTypes.string,
    labelText: PropTypes.string,
    dropDownSize: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),  // Add value prop
    onChange: PropTypes.func.isRequired,  // Add onChange prop
};

CustomTextArea.propTypes = {
    rows: PropTypes.string,
    col: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    labelText: PropTypes.string,
    value: PropTypes.string,  // Add value prop
    name: PropTypes.string,  // Add name prop
    onChange: PropTypes.func.isRequired,  // Add onChange prop
};
export { CustomInputField, CustomDropDownField, NewFooter ,ReusableButton };
