import React from 'react'
import Header from '../layouts/Header'

import Sidebar from '../layouts/Sidebar'
import SectionHeader from '../CustomComponents/SectionHeader'
import { CustomDropDownField, CustomInputField, CustomTextArea, NewFooter } from '../CustomComponents/ReusableComponents'

const TransactionForm = () => {

  const AccountType = [
    { id: 1, text: "Personal" },
    { id: 2, text: "Nominal" },
    { id: 3, text: "Real" },
  ];


  return (
    <>
      <Header />
      <Sidebar />
      {/* <MainContainer> */}
      <SectionHeader sectionHeading="Transaction Form" />
      <div className="row">
        <CustomDropDownField options={AccountType} labelText="Transaction Type"
        />
        <CustomInputField labelText="Amount" inputType="Number" />
        <CustomDropDownField options={AccountType} labelText="From Account"
        />
        <CustomDropDownField options={AccountType} labelText="To Account"
        />
        <CustomInputField labelText="Transaction Date" inputType="date" />
        <div className="col-lg-8">
          <CustomTextArea labelText="Description" placeholder="Enter Description Here ..." />
        </div>
      </div>
      <div className=" d-flex justify-content-end my-3">

        <NewFooter />
      </div>
      {/* </MainContainer> */}
    </>
  )
}

export default TransactionForm