import React, { useEffect } from "react";

function QuickbooksCallback() {
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const state = queryParams.get("state");
    
        if (state) {
            const currentHost = window.location.host;
            const baseDomain = currentHost.includes(".")
                ? currentHost.split(".").slice(-2).join(".")
                : currentHost;
    
            const currentPathWithParams = `${window.location.pathname}${window.location.search}`; // Include full path with query params
            const subdomainUrl = `https://${state}.${baseDomain}${currentPathWithParams}`;
            window.location.href = subdomainUrl; // Redirect to the same route on the subdomain
        }
    }, []);
        

    return (
        <div className="main-wrapper login-body">
            <div className="loader">
                Redirecting to your Quickbook integration dashboard...
            </div>
        </div>
    );
}

export default QuickbooksCallback;
