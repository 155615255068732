import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import './Loader.css'; // Import CSS without assigning to a variable
const Loader = ({ isLoading, className = '' }) => {
    return (
        isLoading && (
            <div className={`loader-overlay  ${className}`}>
                <div className='spinner-border text-primary m-2'></div>
            </div>
        )
    );
};

// Add prop types
Loader.propTypes = {
    isLoading: PropTypes.bool.isRequired, // Validate that isLoading is a required boolean
    className: PropTypes.string, // Optional className for additional styling
};

export default Loader;
