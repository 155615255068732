import React, { useState, useEffect } from "react";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import PropTypes from "prop-types";
import {
    CustomDropDownField,
    CustomInputField,
    CustomTextArea
} from "../../CustomComponents/ReusableComponents";
import axios from "axios";

const AddInvoice = ({ auth }) => {
    const DISCOUNT_TYPES = {
        PERCENT: "%",
        FLAT: "flat"
    };

    const [formData, setFormData] = useState({
        customer_id: "1",
        account_id: "1",
        invoice_date: "2024-09-20T10:50",
        invoice_due_date: "2024-09-20T10:50",
        invoice_number: "234",
        order_no: "123",
        subject: "gasd",
        customer_notes: "dsadasd",
        terms_conditions: "dasdas",
        currency_id: 1,
        amount:100,
        status: "pending",
        // items: [
        //     {
        //         item_id: 1,
        //         quantity: 1,
        //         price: 0,
        //         discount_type: "%",//DISCOUNT_TYPES.PERCENT,
        //         discount_val: 0
        //     }
        // ],
        items: [
          {
              item_id: "2",
              quantity: 1,
              price: 200,
              discount_type: "percentage",
              discount_val: "1"
          }
      ],
        shipping_charges: 0,
        additional_charges: 0
    });
    const [currency, setCurrency] = useState([]);
    const [accountTypes, setAccountTypes] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [menu, setMenu] = useState(false);
    const [error, setError] = useState("");

    const toggleMobileMenu = () => {
        setMenu(!menu);
    };

    const calculateTotal = () => {
        const itemsTotal = formData.items.reduce((acc, item) => {
            const price = parseFloat(item.price) || 0;
            const discount =
                item.discount_type === DISCOUNT_TYPES.PERCENT
                    ? (price * parseFloat(item.discount_val || 0)) / 100
                    : parseFloat(item.discount_val || 0);
            const quantity = parseFloat(item.quantity) || 0;
            return acc + (price - discount) * quantity;
        }, 0);

        const shippingCharges = parseFloat(formData.shipping_charges) || 0;
        const additionalCharges = parseFloat(formData.additional_charges) || 0;

        return itemsTotal + shippingCharges + additionalCharges;
    };

    const handleItemChange = (index, e) => {
        const { name, value } = e.target;
        const updatedItems = [...formData.items];
        updatedItems[index][name] = value;

        if (name === "item_id") {
            const selectedItem = items.find(item => item.id === value);
            if (selectedItem) {
                updatedItems[index].price = selectedItem.selling_price;
            }
        }

        setFormData({ ...formData, items: updatedItems });
    };

    const addItem = () => {
        setFormData({
            ...formData,
            items: [
                ...formData.items,
                {
                    item_id: "",
                    quantity: 1,
                    price: 0,
                    discount_type: DISCOUNT_TYPES.PERCENT,
                    discount_val: 0
                }
            ]
        });
    };

    const removeItem = index => {
        const updatedItems = formData.items.filter((_, i) => i !== index);
        setFormData({ ...formData, items: updatedItems });
    };

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
                    {
                        models: [
                            { model_name: "Customer" },
                            { model_name: "Account" },
                            { model_name: "Currency" },
                            { model_name: "Item" }
                        ]
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`
                        }
                    }
                );
                setAccountTypes(response.data.content.Account || []);
                setCustomers(response.data.content.Customer || []);
                setCurrency(response.data.content.Currency || []);

                // setCurrency(response.data).content.currency || []);
            } catch (err) {
                setError("Failed to load dropdown data");
            }
        };

        fetchDropdownData();
    }, [auth]);

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_V1_BASE_URL}/sales_invoices`,
                { ...formData, items: formData.items },
                {
                    headers: {
                        Authorization: `Bearer ${auth}`,
                        "Content-Type": "application/json"
                    }
                }
            );
            console.log("Invoice created successfully", response.data);
        } catch (err) {
            console.error("Failed to create invoice", err);
            setError("Failed to create invoice");
        }
    };

    // Sample items data (this should be fetched from your API)
    const items = [
        { id: "1", name: "Item 1", selling_price: 100 },
        { id: "2", name: "Item 2", selling_price: 200 }
        // Add more items as necessary
    ];

    return (
        <>
            <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
                <Header onMenuClick={toggleMobileMenu} />
                <Sidebar />
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <div className="card mb-0">
                            <div className="card-body">
                                <div className="page-header">
                                    <div className="content-page-header">
                                        <h5>Add Invoice</h5>
                                    </div>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <CustomDropDownField
                                            options={customers.map(c => ({
                                                value: c.id,
                                                label: c.name
                                            }))}
                                            labelText="Customer"
                                            name="customer_id"
                                            onChange={handleChange}
                                        />
                                        <CustomDropDownField
                                            options={accountTypes.map(a => ({
                                                value: a.id,
                                                label: a.name
                                            }))}
                                            labelText="Account"
                                            name="account_id"
                                            onChange={handleChange}
                                        />
                                        <CustomInputField
                                            inputType="date"
                                            labelText="Invoice Date"
                                            name="invoice_date"
                                            value={formData.invoice_date}
                                            onChange={handleChange}
                                        />
                                        <CustomInputField
                                            inputType="date"
                                            labelText="Invoice Due Date"
                                            name="invoice_due_date"
                                            value={formData.invoice_due_date}
                                            onChange={handleChange}
                                        />
                                        <CustomInputField
                                            inputType="number"
                                            labelText="Invoice Number"
                                            name="invoice_number"
                                            value={formData.invoice_number}
                                            onChange={handleChange}
                                        />
                                        <div className="col-lg-6 my-3">
                                            <CustomTextArea
                                                rows={5}
                                                name="customer_notes"
                                                value={formData.customer_notes}
                                                onChange={handleChange}
                                                placeholder="Customer Notes"
                                            />
                                        </div>

                                        {error && (
                                            <p className="text-danger">
                                                {error}
                                            </p>
                                        )}
                                        <div className="d-flex justify-content-end py-2">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={addItem}
                                            >
                                                <i
                                                    className="fa fa-plus-circle me-2"
                                                    aria-hidden="true"
                                                ></i>
                                                Add Item
                                            </button>
                                        </div>
                                        <div className="form-group-item">
                                            <div className="card-table">
                                                <div className="card-body">
                                                    <div className="table-responsive no-pagination">
                                                        <table className="table table-center table-hover datatable">
                                                            <thead className="thead-light">
                                                                <tr className="text-center">
                                                                    <th>
                                                                        Item
                                                                    </th>
                                                                    <th>
                                                                        Quantity
                                                                    </th>
                                                                    <th>
                                                                        Rate
                                                                    </th>
                                                                    <th>
                                                                        Discount
                                                                        Type
                                                                    </th>
                                                                    <th>
                                                                        Discount
                                                                    </th>
                                                                    <th>
                                                                        Amount
                                                                    </th>
                                                                    <th className="no-sort">
                                                                        Action
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {formData.items.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => (
                                                                        <tr
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <td>
                                                                                <select
                                                                                    className="form-control"
                                                                                    name="item_id"
                                                                                    value={
                                                                                        item.item_id
                                                                                    }
                                                                                    onChange={e =>
                                                                                        handleItemChange(
                                                                                            index,
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <option value="">
                                                                                        Select
                                                                                        Item
                                                                                    </option>
                                                                                    {items.map(
                                                                                        itemOption => (
                                                                                            <option
                                                                                                key={
                                                                                                    itemOption.id
                                                                                                }
                                                                                                value={
                                                                                                    itemOption.id
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    itemOption.name
                                                                                                }
                                                                                            </option>
                                                                                        )
                                                                                    )}
                                                                                </select>
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    name="quantity"
                                                                                    value={
                                                                                        item.quantity
                                                                                    }
                                                                                    onChange={e =>
                                                                                        handleItemChange(
                                                                                            index,
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    name="price"
                                                                                    value={
                                                                                        item.price
                                                                                    }
                                                                                    onChange={e =>
                                                                                        handleItemChange(
                                                                                            index,
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <select
                                                                                    className="form-control"
                                                                                    name="discount_type"
                                                                                    value={
                                                                                        item.discount_type
                                                                                    }
                                                                                    onChange={e =>
                                                                                        handleItemChange(
                                                                                            index,
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <option
                                                                                        value={
                                                                                            DISCOUNT_TYPES.PERCENT
                                                                                        }
                                                                                    >
                                                                                        %
                                                                                    </option>
                                                                                    <option
                                                                                        value={
                                                                                            DISCOUNT_TYPES.FLAT
                                                                                        }
                                                                                    >
                                                                                        Flat
                                                                                    </option>
                                                                                </select>
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    name="discount_val"
                                                                                    value={
                                                                                        item.discount_val
                                                                                    }
                                                                                    onChange={e =>
                                                                                        handleItemChange(
                                                                                            index,
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <span>
                                                                                    {(
                                                                                        (item.price -
                                                                                            (item.discount_type ===
                                                                                            DISCOUNT_TYPES.PERCENT
                                                                                                ? item.price *
                                                                                                  (item.discount_val /
                                                                                                      100)
                                                                                                : item.discount_val)) *
                                                                                        item.quantity
                                                                                    ).toFixed(
                                                                                        2
                                                                                    )}
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                              <div className="d-flex align-items-center justify-content-evenly">
                                                                                <button
                                                                                    type="button"
                                                                                    className="table_action_button"
                                                                                    onClick={() =>
                                                                                        removeItem(
                                                                                            index
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <i className="fe fe-trash-2" />
                                                                                </button>
                                                                                {/* <button type="button" className="btn btn-danger" onClick={() => removeItem(index)}>
                                                                                Remove
                                                                            </button> */}
                                                                              </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                )}
                                                                <tr>
                                                                    <td
                                                                        colSpan="5"
                                                                        className="text-end fw-bold"
                                                                    >
                                                                        Total
                                                                    </td>
                                                                    <td
                                                                        colSpan="2"
                                                                        className="fw-bold"
                                                                    >
                                                                        $
                                                                        {calculateTotal().toFixed(
                                                                            2
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <CustomInputField
                                                labelText="Order No"
                                                inputType="number"
                                                name="order_no"
                                                value={formData.order_no}
                                                onChange={handleChange}
                                                placeholder="Add Order Number here"
                                                inputWrapper="col-lg-6"
                                            />
                                            <CustomInputField
                                                labelText="Subject"
                                                inputType="text"
                                                name="subject"
                                                value={formData.subject}
                                                onChange={handleChange}
                                                placeholder="Mention Subject here"
                                                inputWrapper="col-lg-6"
                                            />
                                            <div className="col-lg-6">
                                                <CustomTextArea
                                                    rows={5}
                                                    name="terms_conditions"
                                                    value={
                                                        formData.terms_conditions
                                                    }
                                                    onChange={handleChange}
                                                    placeholder="Terms & Conditions"
                                                />
                                            </div>
                                            <CustomDropDownField
                                                labelText="Currency"
                                                options={currency.map(cur => ({
                                                    value: cur.id,
                                                    label: cur.name
                                                }))}
                                                name="currency"
                                                onChange={handleChange}
                                            />
                                            <CustomDropDownField
                                                labelText="Status"
                                                options={[
                                                    {
                                                        value: "pending",
                                                        label: "Pending"
                                                    },
                                                    {
                                                        value: "completed",
                                                        label: "Completed"
                                                    }
                                                ]} // Example status options
                                                name="status"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="text-end">
                                        <button
                                            type="reset"
                                            className="btn btn-primary cancel me-2"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Save & Send
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

AddInvoice.propTypes = {
    auth: PropTypes.string.isRequired
};

export default AddInvoice;
