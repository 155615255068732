import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types'; 
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(localStorage.getItem('token'));
    const [branchId, setBranchId] = useState(sessionStorage.getItem('branch_id') || '');
    const [permissions, setPermissions] = useState({});
    const [roleId, setRoleId] = useState(sessionStorage.getItem('role_id'));

    const updateBranchId = (newBranchId) => {
        setBranchId(newBranchId);
        sessionStorage.setItem('branch_id', newBranchId);
    };

    const updateRoleId = (newRoleId) => {
        setRoleId(newRoleId);
        sessionStorage.setItem('role_id', newRoleId);
        fetchPermissions(); // Fetch permissions for the new role
    };

    const login = async (token) => {
        setAuth(token);
        localStorage.setItem('token', token);
        await fetchPermissions();
    };

    const logout = () => {
        setAuth(null);
        setPermissions({});
        localStorage.removeItem('token');
        sessionStorage.removeItem('branch_id');
        sessionStorage.removeItem('role_id');
        sessionStorage.removeItem('user_id');
    };

    const fetchPermissions = async () => {
        if (!auth || !roleId) return;

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_V1_BASE_URL}/roles/${roleId}/permissions`, {
                headers: {
                    Authorization: `Bearer ${auth}`,
                },
            });

            if (Array.isArray(response.data)) {
                const permissionsMap = response.data.reduce((acc, permObj) => {
                    const [key, value] = Object.entries(permObj)[0];
                    acc[key] = value;
                    return acc;
                }, {});
                setPermissions(permissionsMap);
            } else {
                console.warn('Unexpected permissions data format:', response.data);
                setPermissions({});
            }
        } catch (error) {
            console.error('Failed to fetch permissions:', error);
            setPermissions({});
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setAuth(token);
            fetchPermissions();
        }
    }, [roleId]);

    return (
        <AuthContext.Provider
            value={{ auth, login, logout, branchId, updateBranchId, permissions, setPermissions, roleId, updateRoleId }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node,
};

export const useAuth = () => useContext(AuthContext);
