import React from "react";
import PropTypes from "prop-types"; 

const SectionHeader = ({ sectionHeading }) => {
    return (
        
                <div className="content-page-header">
                    <h5>{sectionHeading}</h5>
                </div>
            
        
    );
};

// Define prop types
SectionHeader.propTypes = {
    sectionHeading: PropTypes.string.isRequired, // sectionHeading is a required string
};

export default SectionHeader;
