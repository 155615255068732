import React, { useState, useEffect } from 'react';
import "../_components/antd.css";
import { Table, Tooltip } from 'antd';
import {
  onShowSizeChange,
  itemRender,
} from "../_components/paginationfunction";
import { Link, useHistory } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
// import AddAccount from "./addVendor";
import { filterIcon } from "../_components/Imagepath";
import axios from 'axios';
import { useAuth } from "../contexts/AuthContext";
import Sidebar from '../layouts/Sidebar';
import Header from '../layouts/Header';

const TransactionComponent = () => {
  const { auth } = useAuth();
  const history = useHistory();
  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [columns, setColumns] = useState([]);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const fetchTransactionTypesDetails = async () => {
    try {
      const response = await axios.get('http://127.0.0.1:8000/api/v1/transactions', {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      });
      console.log(response.data);
      
      const records = response.data.records;
      const headers = response.data.headers;

      const dynamicColumns = headers.map(header => ({
        title: header.display_name,
        dataIndex: header.field_name,
        sorter: header.sortable ? (a, b) => a[header.field_name]?.localeCompare(b[header.field_name]) : null,
        render: (text) => text,
      }));

      dynamicColumns.push({
        title: 'Actions',
        dataIndex: 'actions',
        render: (_, record) => (
          <div className="d-flex align-items-center">
            <button 
              className="btn btn-action-icon me-2" 
              onClick={() => handleEdit(record.id)}
            >
              <i className="far fa-edit me-2" />
              Edit
            </button>
            <button 
              className="btn btn-action-icon" 
              onClick={() => handleDelete(record.id)}
            >
              <i className="far fa-trash-alt me-2" />
              Delete
            </button>
          </div>
        ),
      });

      setColumns(dynamicColumns);
      setTransactionData(records);
    } catch (error) {
      console.error("Error fetching customer details:", error);
    }
  };


  const handleEdit = (id) => {
    history.push(`/template/edit-transaction/${id}`);
  };

  const handleDelete = () => {
    console.log("delete!!");
  }
  useEffect(() => {
    fetchTransactionTypesDetails();
  }, [auth]);

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />

        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* Page Header */}
            <div className="page-header">
              <div className="content-page-header ">
                <h5>Transactions</h5>
                <div className="list-btn">
                  <ul className="filter-list">
                    <li>
                      <Link
                        to="#"
                        className="btn btn-filters w-auto popup-toggle me-2"
                        onClick={() => setShow(!show)}
                      >
                        <span className="me-2">
                           <Tooltip title="Filter">
                            <img src={filterIcon} alt="filter" />
                          </Tooltip>
                        </span>
                        Filter
                      </Link>
                    </li>
                    <li className="">
                      <div className="dropdown dropdown-action me-2">
                        <Link
                          to="#"
                          className="btn-filters me-2"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span>
                          <Tooltip title="Download" placement="bottom">
                          <i className="fe fe-download" />
                        </Tooltip>
                          </span>
                        </Link>
                        <div className="dropdown-menu dropdown-menu-end">
                          <ul className="d-block">
                            <li>
                              <Link
                                className="d-flex align-items-center download-item"
                                to="#"
                                download=""
                              >
                                <i className="far fa-file-pdf me-2" />
                                PDF
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="d-flex align-items-center download-item"
                                to="#"
                                download=""
                              >
                                <i className="far fa-file-text me-2" />
                                CVS
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <Link className="btn-filters" to="#">
                        <span>
                        <Tooltip title="Print" placement="bottom">
                        <i className="fe fe-printer" />
                      </Tooltip>
                        </span>{" "}
                      </Link>
                    </li>
                    <li>
                      <Link className="btn btn-import" to="#">
                        <span>
                          <FeatherIcon icon="check-square" className="me-2" />{" "}
                          Import
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="btn btn-primary"
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#add_transaction_types"
                      >
                        <i
                          className="fa fa-plus-circle me-2"
                          aria-hidden="true"
                        />
                        Add Transaction Types
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            {/* Search Filter */}
            <div id="filter_inputs" className="card filter-card">
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-sm-6 col-md-3">
                    <div className="input-block mb-3">
                      <label>Name</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <div className="input-block mb-3">
                      <label>Email</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <div className="input-block mb-3">
                      <label>Phone</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Search Filter */}
            <div className="row">
              <div className="col-sm-12">
                <div className=" card-table">
                  <div className="card-body vendors">
                    <div className="table-responsive table-hover table-striped">
                    <Table
                        className="table"
                        pagination={{
                          total: transactionData.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        columns={columns}
                        dataSource={transactionData}
                        rowKey={(record) => record.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <AddAccount setShow={setShow} show={show} /> */}

        <div className="modal custom-modal fade" id="add_vendor" role="dialog">
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-header border-0 pb-0">
                <div className="form-header modal-header-title text-start mb-0">
                  <h4 className="mb-0">Add Vendor</h4>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-12 col-sm-12">
                    <div className="input-block mb-3">
                      <label>Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Name"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-12">
                    <div className="input-block mb-3">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Email Address"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-12">
                    <div className="input-block mb-3">
                      <label>Phone Number</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Phone Number"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-12">
                    <div className="input-block mb-3 mb-0">
                      <label>Balance</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Balance Amount"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="btn btn-primary paid-cancel-btn me-2"
                >
                  Cancel
                </Link>
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="btn btn-primary paid-continue-btn"
                >
                  Add Vendor
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="modal custom-modal fade" id="edit_vendor" role="dialog">
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-header border-0 pb-0">
                <div className="form-header modal-header-title text-start mb-0">
                  <h4 className="mb-0">Edit Vendor</h4>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="input-block mb-3">
                      <label>Name</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue="John Smith"
                        placeholder="Enter Name"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="input-block mb-3">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue="john@example.com"
                        placeholder="Select Date"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="input-block mb-3">
                      <label>Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue="+1 989-438-3131"
                        placeholder="Enter Reference Number"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="input-block mb-3 mb-0">
                      <label>Balance</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue="$4200"
                        placeholder="Enter Reference Number"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="btn btn-primary paid-cancel-btn me-2"
                >
                  Cancel
                </Link>
                <Link
                  to="#"
                  data-bs-dismiss="modal"
                  className="btn btn-primary paid-continue-btn"
                >
                  Update
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal custom-modal fade"
          id="delete_modal"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content">
              <div className="modal-body">
                <div className="form-header">
                  <h3>Delete Vendor</h3>
                  <p>Are you sure want to delete?</p>
                </div>
                <div className="modal-btn delete-action">
                  <div className="row">
                    <div className="col-6">
                      <button
                        type="reset"
                        data-bs-dismiss="modal"
                        className="w-100 btn btn-primary paid-continue-btn"
                      >
                        Delete
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        type="submit"
                        data-bs-dismiss="modal"
                        className="w-100 btn btn-primary paid-cancel-btn"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionComponent;
