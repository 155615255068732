import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import LedgerModal from "../common/modal/ledgermodal";
import { useAuth } from '../contexts/AuthContext';



// import { avatar14 } from "../../_components/Imagepath";
// import FeatherIcon from "feather-icons-react/build/FeatherIcon";



const Journal = () => {

    const { auth: authToken } = useAuth();
    const { id } = 1;
  // API base URL and token
  //const API_BASE_URL = "https://aia.test/api/v1"; // Replace with actual API base URL
  //const auth = "15|hRPtHQqbt3YPxBMp4dlrbP5lVWyviScN6gN9kS4h6e52834d"; // Replace with actual auth token
  //const API_BASE_URL = process.env.REACT_APP_API_V1_BASE_URL;


//   const { auth } = useAuth();
//     const { id } = useParams();
//     const API_BASE_URL = process.env.REACT_APP_API_V1_BASE_URL;


    const [journalData, setJournalData] = useState({});
    const [entries, setEntries] = useState([]);
    const [data, setData] = useState([]);


    useEffect(() => {
      const apiReqHeaders = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      };

      // Fetch journal data and its entries
      axios.get(`${process.env.REACT_APP_API_V1_BASE_URL}/newjournals/TRX-1`, apiReqHeaders)
        .then(response => {
            if (response && response.data && response.data.length !== 0) {
                console.log('Data found');
                setData(response.data || []);  // Set the journal data
                setJournalData(response.data || []);  // Set ledger data
                setEntries(response.data.entries || []);  // Set entries
              } else {
                console.log('Response is empty');
                setData([]);  // Set an empty array if no data
                setJournalData([]);  // Clear ledger data
                setEntries([]);  // Clear entries
              }
        })
        .catch(error => {
          console.error('Error fetching journal data:', error);
        });
    }, [ id]);
  return (
    <>
      <>
        <div>
          <Header />
          <Sidebar />
          {/* Page Wrapper */}
          <div className="page-wrapper">
          {data ?
            <div className="content container-fluid">
            {/* Page Header */}
            <div className="page-header">
              <div className="content-page-header">
                <h5>Journals</h5>
              </div>
            </div>
            {/* /Page Header */}
            <div className="card p-4 ledger-list">
              <div className="d-flex align-items-center justify-content-between">
                <div className="ledger-info mb-4">
                  <div className="d-flex align-items-center">
                    <h2>
                    Reference : {journalData.reference	 || ''}
                    </h2>
                  </div>
                  <div className="d-flex align-items-center"><h2> Date: {journalData.date || ''}</h2></div>
                  <div className="d-flex align-items-center"><h2> Date: {journalData.amount}</h2></div>
                </div>
                <div className="list-btn">
                  <ul className="filter-list">
                    <li>
                      <div
                        className="dropdown dropdown-action"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Download"
                      >
                        <Link
                          href="#"
                          className="btn-filters"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span>
                          {/* <FeatherIcon icon="download" /> */}
                          </span>
                        </Link>
                        <div className="dropdown-menu dropdown-menu-end">
                          <ul className="d-block">
                            <li>
                              <Link
                                className="d-flex align-items-center download-item"
                                href="#"
                                download=""
                              >
                                <i className="far fa-file-pdf me-2" />
                                PDF
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="d-flex align-items-center download-item"
                                href="#"
                                download=""
                              >
                                <i className="far fa-file-text me-2" />
                                CVS
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                    <li>
                      <Link
                        className="btn-filters"
                        href="#"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title="print"
                      >
                        <span>
                          {/* <FeatherIcon icon="printer" /> */}
                        </span>{" "}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Table */}
              <div className="row">
                <div className="col-sm-12">
                  <div className="card-table">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-stripped table-hover">
                          <thead className="thead-light">
                            <tr>
                              <th>Account</th>
                              <th>Particular</th>
                              <th>Contact</th>
                              <th>Debits</th>
                              <th>Credits</th>
                            </tr>
                          </thead>
                          <tbody>
                          {entries.map((entry, index) => (
                            <tr key={index}>
                            <td>
                              <h2 className="ledger">
                              {entry.account || 'N/A'}
                              </h2>
                            </td>
                            <td>{entry.particular || 'N/A'}</td>
                            <td><span className="badge bg-success-light">{entry.contact || 'N/A'} </span></td>
                            <td>
                              <span>{entry.is_debit == 1 ? entry.amount : '-'}</span>
                            </td>
                            <td>
                              <span>{entry.is_debit == 0 ? entry.amount : '-'}</span>
                            </td>
                          </tr>
                          ))}
                            <tr>
                              <td colSpan={3}>
                                <p>Total</p>
                                <span></span>
                              </td>
                              <td>
                                <span className="text-success fw-600">
                                ${journalData.debitTotal || '00.00'}
                                </span>
                              </td>
                              <td colSpan={2}>
                                <span className="text-primary fw-600">
                                ${journalData.creditTotal || '00.00'}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="mt-4 float-end">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Table */}
            </div>
            <>
            </>
          </div>
          : 'Not found'
            }
          </div>
          <LedgerModal/>
          {/* /Page Wrapper */}
        </div>
      </>
    </>
  );
};

export default Journal;
