import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import {
    CustomDropDownField, CustomInputField
} from "../CustomComponents/ReusableComponents";
import CustomPopup from '../CustomComponents/CustomPopup';

const AddAccountTypes = () => {
    const { auth } = useAuth();
    const [accountTypes, setAccountTypes] = useState([]);

    const [formData, setFormData] = useState({
        parent_id: '',
        name: '',
        description: ''
    });

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_V1_BASE_URL}/get_dropdown_data`,
                    {
                        models: [
                            { model_name: "AccountType" }
                        ]
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${auth}`
                        }
                    }
                );
                setAccountTypes(response.data.content.AccountType || []);
            } catch (err) {
                // setError("Failed to load dropdown data");
            }
        };

        fetchDropdownData();
    }, [auth]);

    const handleChange = e => {
        const { name, value } = e.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_V1_BASE_URL}/account_types`,
                { ...formData, items: formData.items },
                {
                    headers: {
                        Authorization: `Bearer ${auth}`,
                        "Content-Type": "application/json"
                    }
                }
            );
            console.log("Account Type created successfully", response.data);
        } catch (err) {
            console.error("Failed to create Account Type", err);
        }
    }


    return(
        <CustomPopup label="Account Type" id="add_account" handleSubmit={handleSubmit}>
            <CustomInputField
                inputType="text"
                labelText="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
            />
            <CustomInputField
                inputType="text"
                labelText="Description"
                name="description"
                value={formData.description}
                onChange={handleChange}
            />
            <CustomDropDownField
                options={accountTypes.map(c => ({
                    value: c.id,
                    label: c.name
                }))}
                labelText="Account Type"
                name="parent_id"
                onChange={handleChange}
            /> 
    
      </CustomPopup>
    );
};

export default AddAccountTypes;